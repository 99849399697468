import $store from '../store.js'

const siteName = `Design Studio Eureka!`
const siteUrl = $store.state.domain.full
const siteTwitterCreator = `@matovincetic`

const createSEOMeta = (passedTitle, passedDescription, passedImage, passedRobots) => {
  return {
    title: passedTitle,
    meta: [
      {
        hid: `description`,
        name: `description`,
        content: passedDescription
      },
      {
				hid: 'robots',
				name: 'robots',
				content: passedRobots
			},
  
      // OG
      {
        hid: `og:site_name`,
        property: `og:site_name`,
        content: siteName
      },
      {
        hid: `og:title`,
        property: `og:title`,
        content: passedTitle
      },
      {
        hid: `og:type`,
        property: `og:type`,
        content: `website`
      },
      {
        hid: `og:description`,
        property: `og:description`,
        content: passedDescription
      },
      {
        hid: `og:image`,
        property: `og:image`,
        content: passedImage || false
      },
      {
        hid: `og:url`,
        property: `og:url`,
        content: siteUrl
      },
  
      // TWITTER
      {
        hid: `twitter:card`,
        name: `twitter:card`,
        content: `summary_large_image`
      },
      {
        hid: `twitter:title`,
        name: `twitter:title`,
        content: passedTitle
      },
      {
        hid: `twitter:description`,
        name: `twitter:description`,
        content: passedDescription
      },
      {
        hid: `twitter:image`,
        name: `twitter:image`,
        content: passedImage || false
      },
      {
        hid: 'twitter:creator',
        name: 'twitter:creator',
        content: siteTwitterCreator
      },
      {
        hid: 'twitter:url',
        name: 'twitter:url',
        content: siteUrl
      },
  
      {
        hid: `apple-mobile-web-app-title`,
        name: `apple-mobile-web-app-title`,
        content: passedTitle
      }
    ]
  }
}

export { createSEOMeta }