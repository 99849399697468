<template>
	<main class="page page-aab">
		<h1 class="typography-editorial-big">Allgemeine Auftragsbedingungen (AAB)</h1>
		<div
			class="single-aab"
			v-for="(allgemeineAuftragsbedingung, index) in allgemeineAuftragsbedingungen"
			:key="index"
		>
			<ol>
				<h6>{{ allgemeineAuftragsbedingung.bedingung.name }}</h6>
				<li
					v-for="(bedingung, index) in allgemeineAuftragsbedingung.bedingung.paragraphen"
					:key="index"
				>
					<span>{{ bedingung }}</span>
				</li>
			</ol>
		</div>

    	<comp-footer />
	</main>
</template>

<script>
	import CompFooter from '../components/CompFooter.vue'

	import { createSEOMeta } from "@/helpers/helperMetainfo.js";

	const metaInfos = {
		title: `AAB (Allgemeine Auftragsbedingungen) • Design Studio Eureka!`,
		robots: `index, follow`
	}

	export default {
		components: {
			CompFooter
		},

		data () {
			return {
				allgemeineAuftragsbedingungen: [
					{
						bedingung: {
							name: `Geltung`,
							paragraphen: [
								`Diese Allgemeinen Auftragsbedingungen (AAB) gelten für alle Design-Aufträge (kreative Leistungen) zwischen dem Designeran und dessen Auftraggeber (AG). Sie sind nicht auf den Verkauf von Originalen oder auf gewerbliche Leistungen anzuwenden.`,
							]
						}
					},
					{
						bedingung: {
							name: `Grundlagen der Zusammenarbeit`,
							paragraphen: [
								`Grundlage jedes Auftrags ist ein vom AG vorgegebener Rahmen (Briefing), dessen Anforderungen vom Designer zu erfüllen sind. binnen des Briefings besteht bei der Erfüllung des Auftrags Gestaltungsfreiheit.`,
								`Der Designer schafft das Werk eigenverantwortlich in eigener Person; er ist jedoch berechtigt, zur Durchführung sach- verständige Mitarbeiter oder Kooperationspartner heranzuziehen.`,
								`Allfällige Beratung des Designers bezieht sich ausschließlich auf das Fachgebiet Design, die Haftung für den »Rat des Fachmanns« nach ABGB (§ 1299) ist auf dieses Gebiet beschränkt.`,
								`Der AG sorgt dafür, dass dem Designer alle Unterlagen und Umstände sowie Anweisungen, die zur optimalen Auftragserfüllung notwendig sind, zeitgerecht und vollständig zugänglich gemacht werden.`,
							]
						}
					},
					{
						bedingung: {
							name: `Urheberrecht und Nutzungsrecht`,
							paragraphen: [
								`Soweit zwischen AG und Designer nichts Abweichendes vereinbart wurde, räumt der Designer dem AG ein Werknutzungsrecht (ausschließliches Nutzungsrecht) ein. Hiervon ausgenommen sind allfällige Programmierleistungen.`,
								`Der AG erwirbt mit vollständiger Bezahlung des Gesamt- honorars und der Nebenkosten das vereinbarte Nutzungsrecht an den in Erfüllung des Auftrags geschaffenen Werken in der gelieferten Fassung, für den vereinbarten Zweck und Nutzungs- umfang. Wurden über Nutzungszweck und -umfang keine Vereinbarungen getroffen, gilt der für die Auftragserfüllung erforderliche Mindestumfang. Jede anderweitige oder weitergehende zukünftige Nutzung erfordert die honorarwirksame Zustimmung des Designers.`,
								`Jede Änderung, Bearbeitung oder Nachahmung der zur Nutzung überlassenen Werke ist unzulässig, solange nicht das Recht auf Bearbeitung schriftlich und gegen Honorar eingeräumt wurde.`,
								`Die dem AG (bzw. bei Agenturen deren Kunden), dem Nutzungswerber, eingeräumten Rechte dürfen nur mit ausdrücklicher Zustimmung des Designers an Dritte entgeltlich oder unentgeltlich weitergegeben werden.`,
								`An den Entwürfen, Ausarbeitungen und Computerdaten erwirbt der AG kein Eigentum. Im Fall der Einzelrechtsnachfolge gehen alle Rechte und Pflichten an den Rechtsnachfolger über, jedoch nur in dem zwischen dem Designer DA und seinem Kunden vereinbarten Umfang. Eine allfällige Ausweitung der Nutzung durch den Rechtsnachfolger bedarf in jedem Fall der Zustimmung des Designers.`,
								`Will der AG nach Auftragserfüllung, Rücktritt oder nach Kündigung eines Rahmen- oder Betreuungsvertrages die erarbeiteten oder gestalteten Konzepte, Ideen oder Werke unverändert weiter nutzen, erfordert dies die Einräumung des unbeschränkten Nutzungsrechts; wenn diese von Dritten oder dem AG verändert, aktualisiert oder als Grundlage für Weiterentwicklungen verwendet werden sollen, zusätzlich die Einräumung des Rechts auf Bearbeitung durch Dritte. Wünscht der AG die Übergabe der Computerdaten, erfordert dies eine zusätzliche Vereinbarung.`,
							]
						}
					},
					{
						bedingung: {
							name: `Entgeltlichkeit von Präsentationen`,
							paragraphen: [
								`Alle Leistungen des Designers erfolgen gegen Entgelt, lediglich die zur Offertlegung nötige Erstellung von Leistungs-, Zeit- und Kostenplänen erfolgt kostenlos.`,
								`Die Einladung des AG, eine Präsentation mit Vorentwür- fen zu erstellen, gilt als Auftrag, einen definierten Leistungsinhalt zu erbringen. Die Höhe des Präsentationsentgelts ist frei vereinbar und umfasst, sofern nichts Anderes vereinbart wurde, die Hälfte eines üblichen Gestaltungshonorars als angemessene Entlohnung gemäß §§ 1004, 1152 ABGB. Mit Durchführung der Präsentation gilt ein Präsentationsauftrag als erteilt, angenommen und erfüllt.`,
								`Vergibt ein AG oder Auslober eines Präsentationswettbewerbs nach erfolgter Präsentation überhaupt keinen oder nur einen erheblich reduzierten Auftrag an den Designer oder einen Präsentationsmitbewerber, stehen dem Designer das volle Gestaltungshonorar anstelle des reduzierten Präsentationshonorars zu.`,
								`Das Präsentationsentgelt beinhaltet keine Einräumung von Nutzungsrechten.`,
							]
						}
					},
					{
						bedingung: {
							name: `Leistung, Fremdleistungen und Produktionsüberwachung`,
							paragraphen: [
								`Zur Erbringung der gewünschten Leistung samt Übergabe der Produktionsdaten gilt eine angemessene Entlohnung nach §§ 1004, 1152 ABGB als vereinbart. Die Übergabe von Entwicklungsdaten ist nur dann ein Teil der Leistung, wenn sie schriftlich und gegen entsprechendes zusätzliches Honorar vereinbart wurde.`,
								`.Der Designer ist ermächtigt, mit dem Auftrag in Zusammenhang stehende, notwendige oder vereinbarte Nebenleistungen entweder gegen ortsübliches Entgelt selbst zu erbringen oder im Namen und für Rechnung seines AG an Dritte in Auftrag zu geben.`,
								`Die Koordination sowie die Überwachung der Vervielfäl- tigung/Produktion (wie auch Farbabstimmung oder Drucküber- wachung) können vom AG an externe Producer-Fachleute oder den Designer vergeben werden. Sie erfordern einen getrennten Auftrag und erfolgen gegen Entgelt.`,
							]
						}
					},
					{
						bedingung: {
							name: `Rückgabe und Aufbewahrung`,
							paragraphen: [
								`Der AG erhält alle Unterlagen, Zwischenergebnisse, Entwürfe, Konzeptionsbeschreibungen und Ausarbeitungen zu treuen Handen. Bis zum Erwerb der Nutzungsrechte sowie im Ablehnungsfall (Nutzungsverzicht) ist es dem AG nicht gestattet, davon Ablichtungen herzustellen, sie in Computersystemen abzuspeichern oder Dritten zur Ansicht oder Weiterbearbeitung zugänglich zu machen, ausgenommen zum Zweck der Entscheidungsfindung durch Meinungsforschungsinstitute.`,
								`Entwurfsoriginale und Computerdaten sind dem Designer, sobald sie für die vereinbarte Nutzung nicht mehr erforderlich sind, auf Gefahr und Rechnung des AG unbeschädigt zurückzusenden bzw. zu übergeben.`,
							]
						}
					},

					{
						bedingung: {
							name: `Haftung`,
							paragraphen: [
								`Der Designer haftet nicht für leichte Fahrlässigkeit. Bei grober Fahrlässigkeit hat er bis zur Höhe seines Honorars (ohne Nebenkosten und Umsatzsteuer) einzustehen.`,
								`Mängel sind dem Designer unter Aufforderung zu deren Behebung binnen angemessener Frist unverzüglich nach Emp- fang der Leistungen anzuzeigen. Kosten, die bei Inanspruchnah- me Dritter trotz Bereitschaft des Designers zur Mängelbehebung entstehen, trägt der AG. Ein Nachbesserungsanspruch erlischt nach sechs Monaten.`,
								`Für die rechtliche, insbesondere wettbewerbs-, marken- und verwaltungsrechtliche Zulässigkeit der Entwürfe und Ausarbeitungen übernimmt der Designer keine Haftung. Ebenso haftet er nicht für die Richtigkeit von Text und Bild, wenn Arbeiten vom AG genehmigt wurden oder eine Vorlage zur Kontrolle dem AG zumindest angeboten wurde.`,
								`Soweit der Designer notwendige oder vereinbarte Fremdleistungen im Namen und auf Rechnung des AG an Dritte in Auftrag gibt, sind die jeweiligen Auftragnehmer keine Erfüllungsgehilfen des Designers.`,
								`Die vom AG überlassenen Unterlagen (Fotos, Texte, Modelle, Muster etc.) werden vom Mato unter der Annahme verwendet, dass der AG zu deren Verwendung berechtigt ist und bei Bearbeitung oder Nutzung keine Rechte Dritter verletzt werden. Der AG haftet Mato gemäß § 86 UrhG für jede Art widerrechtlicher Nutzung in doppelter Höhe des für diese Nutzung angemessenen Honorars, soweit eine solche zumindest fahrlässig durch ihn ermöglicht oder geduldet wurde.`,
							]
						}
					},
					{
						bedingung: {
							name: `Namensnennung und Belegmuster`,
							paragraphen: [
								`Der Designer ist gem. § 20 UrhG zur Anbringung seines Namens bzw. Pseudonyms, Firmenwortlauts oder Logos auf jedem von ihm entworfenen Werk/Produkt sowie Werbemittel dafür oder Veröffentlichungen darüber berechtigt. Form und Dauer der Kennzeichnung können mit dem AG abgesprochen werden.`,
								`Dem Designer verbleibt in jedem Fall gem. § 26 UrhG das Recht, Abbildungen der von ihm entworfenen Werke/Pro- dukte zum Zweck der Eigenwerbung (Promotion) in gedruckter Form zu verwenden oder zu diesem Zweck im weltweiten Inter- net bereit zu stellen.`,
								`Bei dreidimensionalen Gegenständen hat der Designer Anspruch auf für ihn kostenlose Überlassung von Ablichtungen der Gegenstände, die mit Hilfe seiner Design-Findung hergestellt wurden, sowie auf Übergabe eines Belegexemplars, soweit letzteres nicht mit unverhältnismäßig hohen Kosten verbunden ist. Bei Druckwerken hat der Designer Anspruch auf zumindest fünf Exemplare der von ihm gestalteten Werke.`,
							]
						}
					},
					{
						bedingung: {
							name: `Rücktritt und Storno`,
							paragraphen: [
								`Der AG und Mato sind berechtigt, nach Vorlage der Erstpräsentation ohne Angabe von Gründen vom Auftrag zurückzutreten, wobei vom AG das Präsentationshonorar gemäß Punkt 4.2. AAB zu bezahlen ist.`,
								`Der AG und der Designer sind berechtigt, nach Vorlage der Erstpräsentation ohne Angabe von Gründen vom Auftrag zurückzutreten, wobei vom AG das Präsentationshonorar gemäß Punkt 4.2. AAB zu bezahlen ist.`,
								`Storniert der AG während der Gestaltungs- oder Ausführungsphase oder binnen einer aufrechten Rahmenvereinbarung durch Gründe, die nicht vom Designer zu verantworten sind, den Auftrag, oder reduziert er den Auftragsumfang, verpflichtet er sich zur Vergütung des Gestaltungshonorars zuzüglich des bis dahin angefallenen Nebenleistungs- und Kostenaufwands.`,
								`Unabhängig davon ist der Designer berechtigt, ein Entgelt für bereitgestellte und nicht genutzte Arbeitskapazität und allenfalls dadurch erlittenen Schaden dem AG in Rechnung zu stellen. Die Verrechnung eines Nutzungsentgelts entfällt, alle Rechte bleiben beim Designer.`,
							]
						}
					},
					{
						bedingung: {
							name: `Schlussbestimmungen`,
							paragraphen: [
								`Der Schriftform bedarf jede von den AAB abweichende oder diese ergänzende Vereinbarung sowie alle Rahmenvereinbarungen.`,
								`Es gilt ausschließlich österreichisches Recht. Erfüllungsort und Gerichtsstand ist der Geschäftssitz des Designers.`,
							]
						}
					},
				],
			}
		},

		metaInfo: createSEOMeta(metaInfos.title, metaInfos.description, metaInfos.image, metaInfos.robots)
	}

</script>

<style lang="scss">
	@import '../assets/css/variables.css';

	.page-aab {
		--paragraph-inset: 1.25em;

		& > * + *:not(.comp-footer) {
			margin-top: var(--spacer-padding);
		}

		.single-aab {
			counter-increment: listNumbering;
			max-width: calc(100vmin - var(--spacer) - var(--spacer));
			margin-inline: auto;

			h6 {
				display: inline-block;
				margin-top: 0;
				margin-right: 0;
				// optically align to counters
				margin-left: var(--paragraph-inset);
				padding: 0;
			}

			ul {

				& > li {
					list-style: none;
					margin-left: calc(var(--border-radius)*-2);
				}

				& > span {
					margin-left: calc(var(--border-radius)*2);
					display: inline-block;
				}

			}

			ol {
				margin: 0 0 0 0;
				padding: 0;
				counter-reset: paragraphNumbering;


				& > * + * {
					margin-top: .5em;
				}

				& > li {
					list-style: none;
					position: relative;

					& > span {
						margin-left: var(--paragraph-inset);
						display: inline-block;
					}

					&:before {
						font-variant-numeric: tabular-nums;

						display: inline-block;
						position: absolute;

						left: calc(var(--border-radius)*2);
						transform: translateX(-100%);

						counter-increment: paragraphNumbering;
						content: counters(listNumbering, ".", decimal-leading-zero) "." counters(paragraphNumbering, ".") "  ";
						content: counters(listNumbering, ".") "." counters(paragraphNumbering, ".") "  ";
						text-align: right;
					}
				}
			}

			&:nth-of-type(10) ol {
				margin: 0 0 0 calc(var(--border-radius) * .5);
			}
		}
	}
</style>
